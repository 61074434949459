<template>
  <main>
    <h3 class="mb-5">Add Special Event</h3>
    <div class="row">
      <div class="col-lg-10">
        <div class="row">
          <div class="col-md-6">
            <label for="from">From</label>
            <b-form-input v-model="from" type="date" id="from"></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="duration">To</label>
            <b-form-input v-model="to" type="date" id="to"></b-form-input>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-4">
            <label for="reward-type">Event Name (en)</label>
            <b-form-input
              :class="{ 'not-valid': !isEngEventValid }"
              v-model="eventEn"
              placeholder="Event Name"
              @blur="englishBlurredOut = true"
            ></b-form-input>
            <span class="error-text" v-if="!isEngEventValid"
              >Name of calendar should only be english letters and numbers</span
            >
          </div>
          <div class="col-md-4">
            <label for="reward-type">اسم الحدث</label>
            <b-form-input
              :class="{ 'not-valid': !isArabEventValid }"
              v-model="eventAr"
              placeholder="اسم الحدث"
              @blur="arabicBlurredOut = true"
            ></b-form-input>
            <span class="error-text" v-if="!isArabEventValid"
              >Name of calendar should only be arabic letters and numbers</span
            >
          </div>
          <div class="col-md-4">
            <label for="reward-type">Image Link</label>
            <b-form-input
              :class="{ 'not-valid': !isImageLinkValid }"
              v-model="imgLink"
              placeholder="Image Link"
            ></b-form-input>
            <span class="error-text" v-if="!isImageLinkValid">Image link is not valid</span>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
        <label for="upload-img">
          <img
            v-if="!url && (!imgLink || (imgLink && !isImageLinkValid))"
            src="@/assets/media/imgs/no-image.jpg"
            class="uploaded-image img-fluid img-thumbnail"
            alt=""
          />
          <img else :src="url || imgLink" class="uploaded-image img-fluid img-thumbnail" alt="" />
        </label>
        <input type="file" id="upload-img" @change="onFileChange" />
      </div>
    </div>

    <div class="mt-5 d-flex justify-content-center">
      <button @click="showTable" :disabled="isDisabled" class="btn w-10 btn-primary">Show</button>
    </div>
    <div v-if="showGrid" class="row mt-5">
      <div @click="openModal(idx)" v-for="(no, idx) in noOfDays" :key="idx" class="col-md-2 custom-card">
        <div class="day-no d-flex justify-content-start">{{ dateno(no) }}</div>
        <template v-if="eventRewards[idx]">
          <div
            v-if="eventRewards[idx].rewardPoolCategory"
            class="d-flex flex-column align-items-center justify-content center"
          >
            <p class="data">{{ eventRewards[idx].reward_type }}</p>
            <p
              class="data"
              v-if="eventRewards[idx].reward_type === 'CASH' || eventRewards[idx].reward_type === 'SUPER_CASH'"
            >
              {{ eventRewards[idx].amount }}
            </p>
            <p class="data" v-if="eventRewards[idx].reward_type === 'BOOST'">{{ eventRewards[idx].duration }}</p>
            <p class="data" v-if="eventRewards[idx].reward_type === 'BOOST'">{{ eventRewards[idx].multiplier }}</p>
          </div>
          <div v-else class="d-flex flex-column align-items-center justify-content center">
            <p class="data">{{ eventType(eventRewards[idx].reward_type).toUpperCase() }}</p>
            <p class="data" v-if="eventRewards[idx].reward_type === '0' || eventRewards[idx].reward_type === '1'">
              {{ eventRewards[idx].amount }}
            </p>
            <p class="data" v-if="eventRewards[idx].reward_type === '2'">{{ eventRewards[idx].duration }}</p>
            <p class="data" v-if="eventRewards[idx].reward_type === '2'">{{ eventRewards[idx].multiplier }}</p>
          </div>
        </template>
      </div>
    </div>

    <div v-if="showGrid" class="d-flex justify-content-center mt-4">
      <button
        @click="onSubmit"
        :disabled="
          eventRewards.length === 0 || isDisabled || eventRewards.length === 0 || eventRewards.length !== this.noOfDays
        "
        class="btn btn-primary"
      >
        Submit
      </button>
    </div>
    <RewardModal type="events" id="add-daily-reward-modal" />
  </main>
</template>

<script>
import RewardModal from '../../modals/RewardModal';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as $ from 'jquery';


export default {
  components: {
    RewardModal,
   
  },
  mounted() {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = '0' + month.toString();
    if (day < 10) day = '0' + day.toString();
    var maxDate = year + '-' + month + '-' + day;

    $('#from').attr('min', maxDate);
    $('#to').attr('min', maxDate);
  },
  data() {
    return {
      from: null,
      to: null,
      eventEn: null,
      eventAr: null,
      showGrid: false,
      noOfDays: null,
      url: null,
      file: null,
      submitted: false,
      imgLink: null,
      isImageLinkValid: true,
      loading: false,
      arabicBlurredOut: false,
      englishBlurredOut: false,
    };
  },
  watch: {
    imgLink() {
      if (this.imgLink) {
        this.checkImage(this.imgLink);
      }
    },
    to(){
      const date1 = new Date(this.from);
      const date2 = new Date(this.to);

      var Difference_In_Time = date2.getTime() - date1.getTime();
      var tempDays = Difference_In_Time / (1000 * 3600 * 24) + 1;

      if(tempDays < this.eventRewards.length){
        const array = [...this.eventRewards];
        array.splice(tempDays);
        this.setEventRewards(array);
        this.noOfDays = tempDays;
      }
    }
  },
  methods: {
    ...mapMutations({
      setSelectedDate: 'setSelectedDate',
      setEventRewards: 'setEventRewards',
    }),
    ...mapActions({
      saveEvent: 'saveEvent',
    }),
    showTable() {
      const date1 = new Date(this.from);
      const date2 = new Date(this.to);

      var Difference_In_Time = date2.getTime() - date1.getTime();
      this.noOfDays = Difference_In_Time / (1000 * 3600 * 24) + 1;
      this.showGrid = true;
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const start = new Date(this.from);
      start.setUTCHours(0, 0, 0, 0);

      const end = new Date(this.to);
      end.setUTCHours(23, 59, 59, 999);

      const data = {
        event: {
          event_name: this.eventEn,
          event_name_ar: this.eventAr,
          event_image: this.imgLink,
          event_start: start,
          event_end: end,
          specialEventRewards: this.eventRewards,
        },
        // file: this.file,
      };

      this.saveEvent({
        data,
        vm: this,
      });
    },
    resetFields() {
      this.from = null;
      this.to = null;
      this.eventAr = null;
      this.eventEn = null;
      this.noOfDays = null;
      this.showGrid = false;
      this.url = null;
      this.imgLink = null;
      this.isImageLinkValid = true;
      this.file = null;
      this.setEventRewards([]);
      this.arabicBlurredOut = false;
      this.englishBlurredOut = false;
    },
    checkImage(url) {
      var s = document.createElement('IMG');
      s.src = url;
      let that = this;
      s.onerror = function() {
        that.isImageLinkValid = false;
      };
      s.onload = function() {
        that.isImageLinkValid = true;
      };
    },
    openModal(no) {
      this.setSelectedDate(no);
      this.$bvModal.show('add-daily-reward-modal');
    },
    dateno(noOfDays) {
      let date = new Date(this.from);
      date.setDate(date.getDate() + noOfDays - 1);
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      return date.toISOString().split('T')[0];
    },

    eventType(type) {
      switch (type) {
        case '0':
          return 'Cash';
        case '1':
          return 'Super Cash';
        case '2':
          return 'Boost';
        default:
          return '';
      }
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.url = URL.createObjectURL(this.file);
    },
  },
  computed: {
    ...mapGetters({
      eventRewards: 'eventRewards',
    }),
    isDisabled() {
      if (
        !this.from ||
        !this.to ||
        !this.eventAr ||
        !this.eventEn ||
        !this.isEngEventValid ||
        !this.isArabEventValid ||
        !this.isImageLinkValid
      ) {
        return true;
      } else {
        return false;
      }
    },
    isEngEventValid() {
      if (
        /^\d+$/.test(this.eventEn) ||
        (this.eventEn && !this.eventEn.match('^[A-Za-z0-9]+$')) ||
        (this.englishBlurredOut && !this.eventEn)
      ) {
        return false;
      } else {
        return true;
      }
    },
    isArabEventValid() {
      if (
        /^\d+$/.test(this.eventAr) ||
        (this.eventAr && !this.eventAr.match('^[\u0621-\u064A0-9 ]+$')) ||
        (this.arabicBlurredOut && !this.eventAr)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  height: fit-content;
  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #3c4eba;
  }

  label {
    color: #3c4eba;
    font-weight: 500;
  }

  .not-valid {
    border: 1px solid red;
    color: red;
  }

  .error-text {
    color: red;
    font-weight: 500;
    font-size: 11px;
  }

  button.w-10 {
    width: 10%;
  }

  .uploaded-image {
    cursor: pointer;
  }

  #upload-img {
    visibility: hidden;
  }

  .custom-card {
    background: #fff;
    cursor: pointer;
    padding: 0;
    padding-top: 5px;

    .day-no {
      background: #add8e6;
      padding-left: 5px;
      border-radius: 5px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 8px;
    }

    &:nth-of-type(6n + 1) {
      border: 1px solid #c5c7cd;
      border-bottom: none;
    }

    &:nth-of-type(25) {
      border-bottom: 1px solid #c5c7cd;
    }

    &:not(:nth-of-type(6n + 1)) {
      border-top: 1px solid #c5c7cd;
      border-right: 1px solid #c5c7cd;
    }

    &:nth-last-of-type(-n + 6) {
      border-bottom: 1px solid #c5c7cd;
    }

    &:hover {
      background: #c5c7cd;

      p.data {
        color: white;
      }

      .day-no {
        background-color: white;
        color: #23395d;
      }
    }

    p.data {
      font-weight: 600;
      color: #23395d;
      margin: 0;
    }
  }
}
</style>
